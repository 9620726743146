import React, { useEffect, useState, useCallback } from "react";
import { Form, Input, Button, Row,Col } from "antd";
import Layout from "antd/lib/layout/layout";
import { useDispatch } from "react-redux";
import AppActionCreators from "../store/app/AppActionCreators";
import { TOAST } from "../helpers/NotificationHelper";
import { clearCookies, getToken, setCookies } from "../helpers/utils";
import { navigateToUrl } from "../helpers/StoreHelper";
import I from 'immutable';
import { Navigate, useNavigate } from "react-router";

export const Login = (props) => {
  const [form] = Form.useForm();
  const [applink, setApplink] = useState();
  const [otpRequested, setOtpRequested] = useState(false);
  const token = getToken()

  const dispatch = useDispatch();

  let navigate = useNavigate()
  
  const login = () => {
    let u = form.getFieldsValue("username").username;
    let p = form.getFieldsValue("password").password;

    dispatch(
      AppActionCreators.requestLogin(u, p, (status, res) => {
        if (status) {
          setOtpRequested(true);
          // setDelegate_id(res.info[0].delegate_id)
          // setBadge(res.info[0].badge)

          console.log(res);
          setCookies("token", res.data.token, "");
          // setCookies("qr_image", res.info[0].qr_image);
          // setCookies("img_link", res.info[0].img_link);
          // setCookies("delegate_id", res.info[0].delegate_id);
          // setCookies("badge", res.info[0].badge);
          navigate('/')
        // } else {
        //   TOAST.error(res.message);
        }
      })
    );
  };

  const onFinish = (values) => {
    // if (otpRequested) {
      login();
    // } else {
      // getOTP();
    // }
  };
  // useEffect(() => {
    // clearCookies();
  // }, []);

  if(token){
    return <Navigate to="/venues" />
  }

  return (
    <Layout 
      style={{
        height: "100vh",
        justifyContent: "center",
        color: "#fff",
        padding: {xl:130},
        backgroundSize: "cover",
        backgroundImage:
          "url(login-bg.jpg)",
        alignItems: "center",
      }}
    >
      {/* <Content style={{justifyContent:'center',padding:30}}> */}

      <img
        alt=""
        src="logo.png"
        height={160}
        style={{ width: "auto", objectFit: "none" }}
      />
      <Form
        name="basic"
        form={form}
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        style={{
          width: "100%",
          maxWidth: 400,
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          padding: "1%",
          borderRadius: 5,
          boxShadow: "rgb(14 0 0) 0px 1px 2px 0px",
        }}
      >
        <Form.Item
          label="Email"
          name="username"
          rules={[
            {
              required: true,
              type:'email',
              message: "Please enter valid Email!",
            },
          ]}
        >
          {/* <Input.Group compact> */}
          <Input />

          {/* </Input.Group> */}
        </Form.Item>        
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
            ]}
          >
            <Input.Password />
          </Form.Item>        
        {/* {otpRequested ? (
          <Row
            type="flex"
            style={{ flexDirection: "row-reverse", paddingBottom: 5 }}
          >
            <a
              type="primary"
              href=""
              onClick={() => {
                getOTP();
              }}
            >
              RESEND OTP
            </a>
          </Row>
        ) : null} */}
        <Button type="primary" htmlType="submit">{"Login"}</Button>
      </Form>  
      {applink ?   
      <Row gutter={[8, 8]} style={{marginTop:10}} justify={'center'}>
        {applink.get('android')?
        <Col span={5}>
          <a href={applink.get('android')} >
        <img width={'100%'} src={'/playstore.png'} />
        </a>
        </Col>:null}
        {applink.get('iphone')?
        <Col span={5}>
        <a href={applink.get('iphone')} >
        <img width={'100%'} src={'/appstore.png'} />
        </a>
        </Col>:null}
      </Row> : null }
    </Layout>
  );
};

export default Login;
