export const STRINGS = {
  APP_NAME: 'Nevendo',
  MENU: {
    HOME: 'Home',
    VENUES: 'Venues',
    LAYERS: 'Layers',
    POINTS: 'Points',
    ROUTES: 'Routes',
    PATH_FINDER: 'Path Finder',

    DASHBOARD: 'Dashboard',
    EVENT: 'Event',
    EVENT_INFO: 'Info',
    EVENT_FUNCTIONALITIES: 'Functionalities',
    EVENT_DETAILS: 'Details',
    EVENT_VENUES: 'Venues',
    EVENT_SCHEDULE: 'Schedule',
    EVENT_DELEGATES: 'Delegates',
    SETTINGS: 'Settings',
    REPORTS: 'Reports'
  },
  FORM: {
    LABELS: {
      EVENT_NAME: 'Event Name'
    },
    MESSAGES: {
      EVENT_NAME_REQUIRED: 'Please Enter Event Name'
    }
  }
}
