import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects'

import AppSaga from './app/AppSaga'
import VenueSaga from './venue/VenueSaga'

export default function* rootSaga() {
  yield all([
    AppSaga(),
    VenueSaga(),
  ])
}