const PRIMARY = "#2671aa"
const SECONDARY = "#ef6136"

export const COLORS = {
  WHITE: '#fff',
  PRIMARY,
  PRIMARY_DARK: "#113c5a",
  SECONDARY,
  TRANSPARENT: "rgba(0,0,0,0)",

  ORANGE_NEV: "#ED6027",
  DISABLED:'#d9d9d9',

  HEADER_BG: '#fff',
  CONTENT_BG: "#e9e9e9",
  CONTENT_BG_BORDER: "#e9e9e9",
  CONTENT_CARD_HEADER_BG: '#f9ddd2',
  CONTENT_CARD_HEADER_FG: '#666',
  HALL_TAG_FB: '#757271',
  HALL_TAG_BG: '#e9edf0',
  HALL_DELETE: SECONDARY,

  CARD_HEADER_BG: "#003656",
  PROGRESS_BAR_UNFILLED: "#D0F0F8",
  PROGRESS_BAR_FILLED: "#2E91C9"

}
