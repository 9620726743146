import Notification from 'antd/lib/notification'

export const TOAST = (function () {
  Notification.config({
    placement: 'topRight',
    bottom: 50,
    duration: 4
  })

  const mergeOptions = (msg, opt) => {
    return Object.assign({},
      {
        message: msg
      },
      opt
    )
  }
  return {
    default: (msg, opt = {}) => {
      Notification.open(mergeOptions(msg, opt))
    },
    error: (msg, opt) => {
      Notification.error(mergeOptions(msg, opt))
    },
    info: (msg, opt) => {
      Notification.info(mergeOptions(msg, opt))
    },
    warning: (msg, opt) => {
      Notification.warning(mergeOptions(msg, opt))
    },
    success: (msg, opt) => {
      Notification.success(mergeOptions(msg, opt))
    }
  }
})()
