// import { push, replace } from 'connected-react-router'
import { useNavigate } from "react-router-dom";
import Store from '../store/store'
import { removeTokenFromLocalStorage } from './StorageHelper';

function NavigateToUrl1(url, should_replace = false) {
  // let state = Store.getState()
  // if (state.router.location.pathname !== url) {
  //   if (should_replace) {
  //     Store.dispatch(replace(url))
  //   } else {
  //     Store.dispatch(push(url))
  //   }
  // }
  console.log(url);
  const navigate = useNavigate()
  navigate(url)

}
export const navigateToUrl = NavigateToUrl1

export const getCurrentUser = () => Store.getState().app.get('user')

export const isLoggedIn = () => {
  const user = getCurrentUser()
  return !!user.get('token') //&& user.get('email')
}

export const logoutUser = () => {
  // Store.dispatch(AppActionCreators.setUserData({ token: '' }))
  setTimeout(() => {
    removeTokenFromLocalStorage()
    window.location = '/login'
  }, 100)
}

export const hasPermission = permissionId => {
  const user = getCurrentUser()
  switch (permissionId) {
    default:
      return true
  }
}

