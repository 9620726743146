import axios from 'axios'
import { API_BASE_URL } from '../config/constants'
import { getCurrentUser } from './StoreHelper'

import I from 'immutable'
import { asImmutable, getToken } from './utils'

const Axios = axios.create({
  baseURL: API_BASE_URL,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  crossdomain: true,
  headers: {
    'X-User-Agent': `nevendo-dashboard`,
    // 'Access-Control-Allow-Headers': 'application/json',
    // 'Access-Control-Allow-Origin': 'http://boothnavigation.com:8000/',
    // 'Access-Control-Allow-Headers': '*',

    // 'Accept': 'application/json',
    // 'Content-Type': 'text/plain',

    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Credentials": "true",
    // "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
    // "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
  }
})

// Axios.interceptors.response.use(response => {
//   if (response.status >= 400) {
//     const data = {
//       response: {
//         data: response.data
//       },
//       url: response.request.baseURL + response.request.url,
//       request: {
//         data: response.request.data
//       },
//       status: response.status
//     }
//     console.log("ERROR", data)
//   }
//   return response
// })

// const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')
// const url = 'https://...'

// axios.post(url, {
//   headers: {
//     'Authorization': `Basic ${token}`
//   }
// })

export const getAuthHeaders = token1 => {
  const token = getToken();

  let headers = {}
  headers['Authorization'] = `Bearer ${token}`
  return headers
}

const transformErrorResponse = errorResponse => {
  let error
  if (typeof errorResponse == "string") {
    error = {
      message: error
    }
  } else if (errorResponse && errorResponse.detail && typeof errorResponse.detail == 'string') {
    error = {
      message: errorResponse.detail
    }
  } else {
    error = errorResponse
  }
  return error
}

export const getErrorResponse = error => {
  let res = {
    status: undefined,
    response: undefined
  }
  // TODO: handle 403

  if (error.response) {
    // server sent non 2xx response
    res.status = error.response.status
    res.response = transformErrorResponse(error.response.data)
  } else if (error.request) {
    res.response = { message: "Can't connect to server." }
  } else {
    res.success = false
    res.response = { message: 'Request error.' }
  }
  return res
}

const login = (username, password) => {
  return new Promise((resolve, reject) => {
    Axios.post(`auth/login`, {
      username,
      password
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(getErrorResponse(error))
      })
  })
}

const getUserDetails = () => {
  return new Promise((resolve, reject) => {
    Axios.get(`users?data=1`
      , { headers: { ...getAuthHeaders() } }
    )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(getErrorResponse(error))
      })
  })
}

const updateUser = (id, data) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`users/${id}`, {
      ...data
    }, { headers: { ...getAuthHeaders() } })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(getErrorResponse(error))
      })
  })
}



const fetchVenues = () => {
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `venues`
      , { headers: { ...getAuthHeaders() } })
      .then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(getErrorResponse(error))
      })
  })
}

const fetchVenueDetails = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `venues/` + id 
      , { headers: { ...getAuthHeaders() } })
      .then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(getErrorResponse(error))
      })
  })
}


const fetchLayers = (venueId) => {
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `venues/` + venueId + `/layers`
      , { headers: { ...getAuthHeaders() } })
      .then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(getErrorResponse(error))
      })
  })
}

const fetchLayerDetails = (venueId, layerId) => {
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `venues/` + venueId + `/layers/` + layerId,
      { headers: { ...getAuthHeaders() } })
      .then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(getErrorResponse(error))
      })
  })
}

const createVenues = (data, isNew) => {
  return new Promise((resolve, reject) => {
    if (isNew) {
      axios.put(API_BASE_URL + `venues/${data.venue_id}`, {
        ...data
        }, { headers: { ...getAuthHeaders() }
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(getErrorResponse(error) || error)
        })
    } else {
      axios.post(API_BASE_URL + `venues`, {
        ...data
        }, { headers: { ...getAuthHeaders() }
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(getErrorResponse(error) || error)
        })
    }
  })
}

const createLayers = (id, data, image) => {
  let formData = new FormData()

  Object.entries(data).map(([key, value]) => {
    formData.append(key, value)
  })
  formData.append('layer_image', image)

  return new Promise((resolve, reject) => {
    axios.post(API_BASE_URL + `venues/${id}/layers`,
      formData,
      {
        headers: {
          ...{ 'Content-Type': 'multipart/form-data' },
          ...getAuthHeaders()
        }
      }
    )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(getErrorResponse(error))
      })
  })
}
const updateLayer = (venueId, id, item, image) => {

  let formData = new FormData()

  Object.entries(item).map(([key, value]) => {
    formData.append(key, value)
  })
  formData.append('layer_image', image)


  return new Promise((resolve, reject) => {
    axios.post(API_BASE_URL + `venues/${venueId}/layers/${id}`,
      formData,

      {
        headers: {
          ...getAuthHeaders()
        }
      }
    )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(getErrorResponse(error))
      })
  })
}

const deleteLayer = (venueId, id,) => {

  return new Promise((resolve, reject) => {
    axios.delete(API_BASE_URL + `venues/${venueId}/layers/${id}`,
      {
        headers: {
          ...getAuthHeaders()
        }
      }
    )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(getErrorResponse(error))
      })
  })
}

const fetchVenuePoints = (venueId, search) => {
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `venues/` + venueId + `/points`, {
      params: {
        'search': search
      },
      headers: { ...getAuthHeaders() }
    })
      .then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(getErrorResponse(error))
      })
  })
}

const fetchPoints = (venueId, layerId, search,type) => {
  let params = {
    'search': search,
  }
  if(type != null){
    params['point_type'] = type
  }
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `venues/` + venueId + `/layers/` + layerId + `/points`, {
      params: params,
      headers: { ...getAuthHeaders() }
    })
      .then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(getErrorResponse(error))
      })
  })
}

const createPoints = (id, layerId, data) => {

  return new Promise((resolve, reject) => {
    axios.post(API_BASE_URL + `venues/${id}/layers/${layerId}/points`,
      data,
      {
        headers: {
          ...getAuthHeaders()
        }
      }
    )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(getErrorResponse(error))
      })
  })
}

const updatePoints = (venueId, id, item) => {
  return new Promise((resolve, reject) => {
    axios.patch(API_BASE_URL + `venues/${venueId}/points/${id}`, {
      ...item,
    },
      {
        headers: {
          ...getAuthHeaders()
        }
      }
    )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(getErrorResponse(error))
      })
  })
}
const deletePoints = (venueId, layerId, pointId) => {
  return new Promise((resolve, reject) => {
    axios.delete(API_BASE_URL + `venues/${venueId}/layers/${layerId}/points/${pointId}`,
      {
        headers: {
          ...getAuthHeaders()
        }
      }
    )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(getErrorResponse(error))
      })
  })
}

const fetchVenueRoutes = (venueId, layerId) => {
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `venues/${venueId}/routes`,  { 
      params: {
        'layer_id': layerId
      },
      headers: { ...getAuthHeaders() } })
      .then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(getErrorResponse(error))
      })
  })
}

const fetchRoutes = (venueId, layerId) => {
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `venues/${venueId}/layers/${layerId}/routes`
      , { headers: { ...getAuthHeaders() } })
      .then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(getErrorResponse(error))
      })
  })
}


const updateRoutes = (venueId, layerId, data) => {
  return new Promise((resolve, reject) => {
    axios.post(API_BASE_URL + `venues/${venueId}/layers/${layerId}/routes`, {
      ...data,
    },
      {
        headers: {
          ...getAuthHeaders()
        }
      }
    )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(getErrorResponse(error))
      })
  })
}


const generateRoutes = (venueId, layerId) => {
  return new Promise((resolve, reject) => {
    axios.post(API_BASE_URL + `venues/${venueId}/layers/${layerId}/generate_lines`, {},
      {
        headers: {
          ...getAuthHeaders()
        }
      }
    )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(getErrorResponse(error))
      })
  })
}


const updateLines = (venueId, layerId,data) => {
  return new Promise((resolve, reject) => {
    axios.post(API_BASE_URL + `venues/${venueId}/layers/${layerId}/update_lines`, {
      'route_data':data,
    },
      {
        headers: {
          ...getAuthHeaders(),
          'Content-Type':'application/json'
        }
      }
    )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(getErrorResponse(error))
      })
  })
}



const fetchPath = (venueId, layerId, id1, id2) => {
  return new Promise((resolve, reject) => {
    // API_BASE_URL + `venues/${venueId}/layers/${layerId}/generate_path`,
    axios.post(API_BASE_URL + `events/${venueId}/generate_path`,
      {
        'point_id_1': id1,
        'point_id_2': id2,
      }, { headers: { ...getAuthHeaders() } })
      .then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(getErrorResponse(error))
      })
  })
}


const fetchAllLines = (venueId, layerId) => {
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `venues/${venueId}/layers/${layerId}/all_lines`
      , { headers: { ...getAuthHeaders() } })
      .then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(getErrorResponse(error))
      })
  })
}

const fetchExhibitors = (venueId, search) => {
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `venues/${venueId}/exhibitors`, {
      params: {
        'search': search
      },
      headers: {
        ...getAuthHeaders()
      }
    }
    )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(getErrorResponse(error))
      })
  })
}

export default {
  login,
  getUserDetails,
  updateUser,


  fetchVenues,
  fetchVenueDetails,
  createVenues,
  fetchLayers,
  fetchLayerDetails,
  createLayers,
  updateLayer,
  deleteLayer,

  fetchVenuePoints,
  fetchPoints,
  createPoints,
  updatePoints,
  deletePoints,
  fetchRoutes,
  fetchVenueRoutes,
  updateRoutes,
  generateRoutes,
  updateLines,
  fetchPath,
  fetchAllLines,

  fetchExhibitors,


}
