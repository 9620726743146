import { call, put, takeEvery, takeLatest, all } from "redux-saga/effects";

import I from 'immutable'

import AppActionTypes from "./AppActionTypes";
import AppActionCreators from "./AppActionCreators";

import { TOAST } from "../../helpers/NotificationHelper";
import ApiHelper from "../../helpers/ApiHelper";
import { isFunction } from "../../helpers/utils";
import log from '../../helpers/logger'


function* fetchUserData() {
    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.getUserDetails)
        if (resData.data) {
            if (resData.data.countries) {
                yield put(AppActionCreators.setCountriesList(I.fromJS(resData.data.countries)))
            }
            if (resData.data.event_types) {
                yield put(AppActionCreators.setEventTypesList(I.fromJS(resData.data.event_types)))
            }
            if (resData.data.social_medias) {
                yield put(AppActionCreators.setSocialMediasList(I.fromJS(resData.data.social_medias)))
            }
            if (resData.data.program_functionalities) {
                yield put(AppActionCreators.setProgramFunctionalityList(I.fromJS(resData.data.program_functionalities)))
            }
        }
        yield put(AppActionCreators.setUserData(I.fromJS(resData.user)))
    } catch (e) {
        // log(e)
        TOAST.error(e.response.message)
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}

function* login(action) {
    const { username, password, cb } = action.payload
    try {
      yield put(AppActionCreators.showLoader())
      let resData = yield call(ApiHelper.login, username, password)
      if (isFunction(cb)) {
        yield call(cb, true, resData)
      }
    } catch (e) {
      log(e)
      TOAST.error(e.response.message)
      if (isFunction(cb)) {
        yield call(cb, false)
      }
    } finally {
      yield put(AppActionCreators.hideLoader())
    }
  }




export default function* appSaga() {
    yield all([
        // takeLatest(AppActionTypes.REQUEST_FETCH_USER_DATA, fetchUserData)
        takeLatest(AppActionTypes.REQUEST_LOGIN, login),
    ]);
}
