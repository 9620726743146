export default {
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  SHOW_DEVICE_PREVIEW: 'SHOW_DEVICE_PREVIEW',
  HIDE_DEVICE_PREVIEW: 'HIDE_DEVICE_PREVIEW',
  SET_SHOW_SAVE_BUTTON: 'SET_SHOW_SAVE_BUTTON',
  SET_BACKGROUND_SAVE_STATUS: 'SET_BACKGROUND_SAVE_STATUS',
  SET_VENUES:'SET_VENUES',
  REQUEST_LOGIN: 'REQUEST_LOGIN',

  
}
