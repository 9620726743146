import VenueActionTypes from './VenueActionTypes'
import { createAction } from '../../helpers/utils';
import AppActionTypes from '../app/AppActionTypes';

export default {
  // showLoader: () => createAction(AppActionTypes.SHOW_LOADER),
  // hideLoader: () => createAction(AppActionTypes.HIDE_LOADER),
  // setSaveButtonStatus: status => createAction(VenueActionTypes.SET_SHOW_SAVE_BUTTON, { status }),
  // setBackgroundSaveStatus: status => createAction(VenueActionTypes.SET_BACKGROUND_SAVE_STATUS, { status }),
  setMarkers: (items) => createAction(VenueActionTypes.SET_MARKERS, { items }),
  addMarker: (item) => createAction(VenueActionTypes.ADD_MARKER, { item }),
  updateMarker: (id, item) => createAction(VenueActionTypes.UPDATE_MARKER, { id, item }),
  setPolyLines: (items) => createAction(VenueActionTypes.SET_POLY_LINES, { items }),
  addPolyLines: (item) => createAction(VenueActionTypes.ADD_POLY_LINES, { item }),
  updateLayer: (id, item, image) => createAction(VenueActionTypes.UPDATE_LAYER, { id, item, image }),
  updateLines: (item) => createAction(VenueActionTypes.UPDATE_LINES, { item }),

  addVenue: (item) => createAction(VenueActionTypes.ADD_VENUE, { item }),


  setVenues: (data) => createAction(VenueActionTypes.SET_VENUES, { data }),
  setVenueDetails: (data) => createAction(VenueActionTypes.SET_VENUES_DETAILS, { data }),
  setLayers: (data) => createAction(VenueActionTypes.SET_LAYERS, { data }),
  addLayer: (data) => createAction(VenueActionTypes.ADD_LAYER, { data }),

  setPoints: (data) => createAction(VenueActionTypes.SET_POINTS, { data }),
  addPoints: (data) => createAction(VenueActionTypes.ADD_POINTS, { data }),
  updatePoints: (data) => createAction(VenueActionTypes.UPDATE_POINTS, { data }),
  deletePoints: (id) => createAction(VenueActionTypes.DELETE_POINTS, { id }),
  selectPoint: (data) => createAction(VenueActionTypes.SELECT_POINT, { data }),
  // selectUpdatePoint: (data) => createAction(VenueActionTypes.SELECT_UPDATE_POINT, { data }),
  selectPointHide: () => createAction(VenueActionTypes.SELECT_POINT_HIDE, {}),

  setRoutes: (data) => createAction(VenueActionTypes.SET_ROUTES, { data }),
  addRoutes: (data) => createAction(VenueActionTypes.ADD_ROUTES, { data }),
  removeRoute: (id) => createAction(VenueActionTypes.REMOVE_ROUTES, { id }),
  // updateRoutes: (data) => createAction(VenueActionTypes.UPDATE_ROUTES, { data }),


  setUserData: user => createAction(VenueActionTypes.SET_USER_DATA, { user }),
  addUserEvent: data => createAction(VenueActionTypes.ADD_USER_EVENT, { data }),
  setEventTypesList: items => createAction(VenueActionTypes.SET_EVENT_TYPES_LIST, { items }),
  setSocialMediasList: items => createAction(VenueActionTypes.SET_SOCIAL_MEDIAS_LIST, { items }),
  setCountriesList: items => createAction(VenueActionTypes.SET_COUNTRIES_LIST, { items }),
  setProgramFunctionalityList: items => createAction(VenueActionTypes.SET_PROGRAM_FUNCTIONALITY_LIST, { items }),
  showDevicePreview: () => createAction(VenueActionTypes.SHOW_DEVICE_PREVIEW),
  hideDevicePreview: () => createAction(VenueActionTypes.HIDE_DEVICE_PREVIEW),
  requestLogin: data => createAction(VenueActionTypes.REQUEST_LOGIN, data),

  requestFetchUserData: () => createAction(VenueActionTypes.REQUEST_FETCH_USER_DATA),
  requestUpdateUserData: data => createAction(VenueActionTypes.REQUEST_UPDATE_USER_DATA, { data }),

  requestFetchVenues: (cb) => createAction(VenueActionTypes.REQUEST_FETCH_VENUES, { cb }),
  requestFetchfetchVenueDetails: (id, cb) => createAction(VenueActionTypes.REQUEST_FETCH_VENUE_DETAILS, { id, cb }),
  requestAddVenues: (data, isNew, cb) => createAction(VenueActionTypes.REQUEST_ADD_VENUES, { data, isNew, cb }),
  requestFetchLayers: (id, cb) => createAction(VenueActionTypes.REQUEST_FETCH_LAYERS, { id, cb }),
  requestFetchLayerDetails: (id, layerId, cb) => createAction(VenueActionTypes.REQUEST_FETCH_LAYER_DETAILS, { id, layerId, cb }),
  requestAddLayer: (id, data, image, cb) => createAction(VenueActionTypes.REQUEST_ADD_LAYER, { id, data, image, cb }),
  requestupdateLayer: (venueId, id, item, image, cb) => createAction(VenueActionTypes.REQUEST_UPDATE_LAYER, { venueId, id, item, image, cb }),
  requestDeleteLayer: (venueId, id) => createAction(VenueActionTypes.REQUEST_DELETE_LAYER, { venueId, id }),

  //delete layer
  requestFetchPoints: (venueId, layerId, search, cb) => createAction(VenueActionTypes.REQUEST_FETCH_POINTS, { venueId, layerId, search, cb }),
  requestFetchLayerPoints: (venueId, layerId, search,type, cb) => createAction(VenueActionTypes.REQUEST_FETCH_LAYER_POINTS, { venueId, layerId, search, type,cb }),
  requestFetchPointsVenue: (venueId, search, cb) => createAction(VenueActionTypes.REQUEST_FETCH_VENUE_POINTS, { venueId, search, cb }),
  requestAddPoints: (venueId, layerId, data, cb) => createAction(VenueActionTypes.REQUEST_ADD_POINTS, { venueId, layerId, data, cb }),
  // requestupdatePoints: (venueId, layerId, data) => createAction(VenueActionTypes.REQUEST_UPDATE_POINTS, { venueId, layerId, data }),

  requestDeletePoint: (venueId, layerId, pointId, cb) => createAction(VenueActionTypes.REQUEST_DELETE_POINTS, { venueId, layerId, pointId, cb }),


  requestFetchRoutes: (venueId, layerId, cb) => createAction(VenueActionTypes.REQUEST_FETCH_ROUTES, { venueId, layerId, cb }),
  requestFetchVenueRoutes: (venueId, layerId,cb) => createAction(VenueActionTypes.REQUEST_FETCH_VENUE_ROUTES, { venueId, layerId, cb }),
  requestFetchAllLines: (venueId, layerId, cb) => createAction(VenueActionTypes.REQUEST_FETCH_ALL_LINES, { venueId, layerId, cb }),
  requestUpdateRoutes: (venueId, layerId, data, cb) => createAction(VenueActionTypes.REQUEST_UPDATE_ROUTES, { venueId, layerId, data, cb }),
  requestgenerateRoutes: (venueId, layerId, cb) => createAction(VenueActionTypes.REQUEST_GENERATE_ROUTES, { venueId, layerId, cb }),
  requestupdateLines: (venueId, layerId, data, cb) => createAction(VenueActionTypes.REQUEST_UPDATE_LINES, { venueId, layerId, data, cb }),
  requestFetchPath: (venueId, layerId, id1, id2, cb) => createAction(VenueActionTypes.REQUEST_FETCH_PATH, { venueId, layerId, id1, id2, cb }),
  requestFetchExhibitors: (venueId, search, cb) => createAction(VenueActionTypes.REQUEST_FETCH_EXHIBITORS, { venueId, search, cb }),



}
