export const BASE_URL = window.__APP_CONF.BASE_URL
export const CDN_URL = window.__APP_CONF.CDN_URL
export const APP_BASE_URL = window.__APP_CONF.APP_BASE_URL
// export const BASE_URL = "http://127.0.0.1:8000/"
// export const BASE_URL = "http://boothnavigation.com:8000/"
// export const BASE_URL = "http://192.168.29.7:8004/"
// export const APP_BASE_URL = "http://192.168.29.7:3000/"
// export const CDN_URL = "window.__APP_CONF.CDN_URL"

const API_VERSION = "v1"
// export const API_BASE_URL = `${BASE_URL}api/${API_VERSION}/`
export const API_BASE_URL = `${BASE_URL}api/navigation/`


export const APP_HEADER_HEIGHT = 56



export const HAS_API = true
export const MENU_SIDER_WIDTH = 180
export const MENU_SIDER_COLLAPSED_WIDTH = 0
export const DEVICE_PREVIEW_WIDTH = 360

export const AppLogo = '/images/logo.png'
export const Upgrade = '/images/icons/systemupdate.svg';
export const Building = '/images/icons/building.svg'
export const MARKER_RED_ICON = APP_BASE_URL+'images/map/location_pin.png'
// export const MARKER_VENUE = 'https://raw.githubusercontent.com/scottdejonge/map-icons/master/src/icons/city-hall.svg'
export const MARKER_VENUE = APP_BASE_URL+'images/map/marker_yellow.png'
export const MARKER_YELLOW = APP_BASE_URL+'images/map/marker_yellow.png'
export const MARKER_DEFAULT = 'https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png'
export const MARKER_RED = APP_BASE_URL+'images/map/marker_red.png'
export const MARKER_BLUE = APP_BASE_URL+'images/map/marker_blue.png'
export const MARKER_GREEN = APP_BASE_URL+'images/map/marker_green.png'


export const PATTERNS = {
  URL: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
  PHONE: /^\+?(?:[0-9] ?){6,14}[0-9]$/,
  EMAIL: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
}
