import React, { Suspense, useState, useCallback, useEffect } from 'react'
import {
  Routes,
  Route,
  Navigate,
  useNavigate
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'


import { createSelector } from 'reselect'
import Layout from 'antd/lib/layout'

import './helpers/logger'
import { getToken, clearCookies, checkLogout, getCookie } from "./helpers/utils";
import AppLoader from './ui/AppLoader'
import Header from './ui/common/Header'
import Sidebar from './ui/common/Sidebar'
import { COLORS } from './config/colors'
import { APP_HEADER_HEIGHT, HAS_API } from './config/constants'
import VenueActionCreators from './store/venue/VenueActionCreators'
import Login from "./pages/Login";

const { Content } = Layout

const Dashboard = React.lazy(() =>
  import(
    /* webpackChunkName: "dashboard" */
    './ui/dashboard/index'
  )
)
const Venues = React.lazy(() =>
  import(
    /* webpackChunkName: "venues" */
    './ui/venues/index'
  )
)


// const Layers = React.lazy(() =>
//   import(
//     /* webpackChunkName: "layersindex" */
//     './ui/layers/index'
//   )
// )
// const LayerListItem = React.lazy(() =>
//   import(
//     /* webpackChunkName: "editlayer" */
//     '../layers/Edit'
//   )
// )
const Points = React.lazy(() =>
  import(
    /* webpackChunkName: "points" */
    './ui/points/points'
  )
)
const RoutePath = React.lazy(() =>
  import(
    /* webpackChunkName: "routes_index" */
    './ui/routes/index'
  )
)


const Layers = React.lazy(() =>
  import(
    /* webpackChunkName: "layers" */
    './ui/layers/ListLayers'
  )
)
const LayerListItem = React.lazy(() =>
  import(
    /* webpackChunkName: "editlayer" */
    './ui/layers/Edit'
  )
)
const MapEdit = React.lazy(() =>
  import(
    /* webpackChunkName: "mapEdit" */
    './ui/MapEdit'
  )
)



const mapState = createSelector(
  ({ app }) => ({
    initialLoad: app.get('initialLoad')
  }),
  ({ initialLoad }) => initialLoad
)

const Home = (props) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false) //isMobile())
  const [mapType, setMapType] = useState(0)

  const isInitialLoad = useSelector(mapState)

  const dispatch = useDispatch()
  let navigate = useNavigate()

  const fetchVenues = useCallback(() => dispatch(VenueActionCreators.requestFetchVenues(async (status, e) => {
    if (await checkLogout(e)) {
      navigate('/logout')
      return
    }

  })), [dispatch])

  useEffect(() => {
    if (isInitialLoad) {
      fetchVenues()
    }
  }, [dispatch])

  useEffect(() => {
    let c = getCookie('mapType')
    setMapType(c)
  }, [dispatch])

  if (isInitialLoad) {
    return <AppLoader />
  }

  return (
    <Layout style={{ height: '100vh' }}>

      <AppLoader />
      <Header {...{ sidebarCollapsed, setSidebarCollapsed }} />
      <Layout >
        <Sidebar {...{ sidebarCollapsed, mapType, setMapType }} />
        <Suspense fallback={'Loading...'}>
          <Content
            style={{
              backgroundColor: COLORS.CONTENT_BG,
              height: "calc(100vh - " + APP_HEADER_HEIGHT + "px)"
            }}
          >

            <Routes>

              <Route path="/" element={<Dashboard />} />
              <Route path="/venues/:venueId/layers" element={<Layers />} />
              <Route path="/venues/:venueId/layers/:layerId" element={<LayerListItem  {...{ mapType, setMapType }}/>} />
              <Route path="/venues/:venueId/layers/:layerId/points" element={<MapEdit type="point" isPoint={true} {...{ mapType, setMapType }} />} />
              <Route path="/venues/:venueId/layers/:layerId/routes" element={<MapEdit type="route" isRoute={true} {...{ mapType, setMapType }} />} />
              <Route path="/venues/:venueId/layers/:layerId/pathfinder" element={<MapEdit type="pathfinder" {...{ mapType, setMapType }} />} />
              <Route path="/venues/:venueId/layers/:layerId/all_lines" element={<MapEdit type="all_lines" {...{ mapType, setMapType }} />} />
              <Route path="/venues/:venueId/points" element={<Points />} />
              <Route path="/venues/:venueId/routes" element={<RoutePath {...{ mapType, setMapType }} />} />
              <Route path="/venues/:venueId/" element={<Venues />} />
              <Route path="/venues/" element={<Venues />} />

              {/* <Route path="" element={<Venues />} > */}
              {/* <Route path="/" element={<Layers />} /> */}
              {/* 
                <Route path="points" element={<Points />} />
                <Route path="routes" element={<RoutePath />} /> */}
            
            
            </Routes>

          </Content>
        </Suspense>
      </Layout>
    </Layout>
  )
}


export default Home