import AppActionTypes from './AppActionTypes'
import { createAction } from '../../helpers/utils';

export default {
  showLoader: () => createAction(AppActionTypes.SHOW_LOADER),
  hideLoader: () => createAction(AppActionTypes.HIDE_LOADER),
  setSaveButtonStatus: status => createAction(AppActionTypes.SET_SHOW_SAVE_BUTTON, { status }),
  setBackgroundSaveStatus: status => createAction(AppActionTypes.SET_BACKGROUND_SAVE_STATUS, { status }),
  requestLogin: (username, password, cb) => createAction(AppActionTypes.REQUEST_LOGIN, { username, password, cb }),
  // setMarkers: (items) => createAction(AppActionTypes.SET_MARKERS, { items }),
  // addMarker: (item) => createAction(AppActionTypes.ADD_MARKER, { item }),
  // updateMarker: (id, item) => createAction(AppActionTypes.UPDATE_MARKER, { id, item }),
  // setPolyLines: (items) => createAction(AppActionTypes.SET_POLY_LINES, { items }),
  // addPolyLines: (item) => createAction(AppActionTypes.ADD_POLY_LINES, { item }),
  // addLayer: (item) => createAction(AppActionTypes.ADD_LAYER, { item }),
  // updateLayer: (item) => createAction(AppActionTypes.UPDATE_LAYER, { item }),
  // updateLines: (item) => createAction(AppActionTypes.UPDATE_LINES, { item }),

  // addVenue: (item) => createAction(AppActionTypes.ADD_VENUE, { item }),
  setVenues: (data) => createAction(AppActionTypes.SET_VENUES, { data }),


  // setUserData: user => createAction(AppActionTypes.SET_USER_DATA, { user }),
  // addUserEvent: data => createAction(AppActionTypes.ADD_USER_EVENT, { data }),
  // setEventTypesList: items => createAction(AppActionTypes.SET_EVENT_TYPES_LIST, { items }),
  // setSocialMediasList: items => createAction(AppActionTypes.SET_SOCIAL_MEDIAS_LIST, { items }),
  // setCountriesList: items => createAction(AppActionTypes.SET_COUNTRIES_LIST, { items }),
  // setProgramFunctionalityList: items => createAction(AppActionTypes.SET_PROGRAM_FUNCTIONALITY_LIST, { items }),
  // showDevicePreview: () => createAction(AppActionTypes.SHOW_DEVICE_PREVIEW),
  // hideDevicePreview: () => createAction(AppActionTypes.HIDE_DEVICE_PREVIEW),
  // requestLogin: data => createAction(AppActionTypes.REQUEST_LOGIN, data),

  // requestFetchUserData: () => createAction(AppActionTypes.REQUEST_FETCH_USER_DATA),
  // requestUpdateUserData: data => createAction(AppActionTypes.REQUEST_UPDATE_USER_DATA, { data }),

  // requestFetchVenues: () => createAction(AppActionTypes.REQUEST_FETCH_VENUES),
  // requestAddVenues: (data, cb) => createAction(AppActionTypes.REQUEST_ADD_VENUES, { data, cb }),
}
