import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import Store from './store/store';
import './index.css';
import "antd/dist/antd.css";
import './styles/tail.css'
import './styles/index.less'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from './pages/Login';
import { clearCookies, getToken } from './helpers/utils';
import Home from './Home';

const root = ReactDOM.createRoot(document.getElementById('root'));

function RequireAuth({ children }) {
  let t = getToken()
  if (!t) {
    return <Navigate to="/login" replace />;
  } else {
    return children
  }
}

function Logout({ children }) {
  clearCookies();
  window.location = '/login'
}

root.render(
  // <React.StrictMode>
  <Provider store={Store}>
    <BrowserRouter>
      <Routes>

        <Route
          path="/logout"
          exact
          element={<Logout />}

        />
        <Route
          path="/login"
          element={<Login />}
        />

        <Route path="*" element={<RequireAuth>
          <Home />
        </RequireAuth>} /> :

      </Routes>
    </BrowserRouter>
  </Provider>
  // </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
