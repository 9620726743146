
import React from 'react'

export const SIDEBAR_ICONS = {
    // HOME: Home,
    // VENUE:Venue,
    // LAYERS:Layers,
    // POINTS:Points,
    // ROUTES:Routes,

    HOME: '/images/icons/website-home-page.svg',
    VENUE: '/images/icons/venue.svg',
    LAYERS: '/images/icons/layers.svg',
    POINTS: '/images/icons/places.svg',
    ROUTES: '/images/icons/routes.svg',
    TAP: '/images/icons/tap.svg',
}