export default {

  SET_MARKERS: 'SET_MARKERS',
  ADD_MARKER: 'ADD_MARKER',
  UPDATE_MARKER: 'UPDATE_MARKER',
  SET_POLY_LINES: 'SET_POLY_LINES',
  ADD_POLY_LINES: 'ADD_POLY_LINES',
  ADD_LAYER: 'ADD_LAYER',
  UPDATE_LAYER: 'UPDATE_LAYER',
  UPDATE_LINES: 'UPDATE_LINES',
  ADD_VENUE: 'ADD_VENUE',
  SET_VENUES_DETAILS: 'SET_VENUES_DETAILS',
  SET_LAYERS: 'SET_LAYERS',
  SET_POINTS: 'SET_POINTS',
  ADD_POINTS: 'ADD_POINTS',
  UPDATE_POINTS:'UPDATE_POINTS',
  DELETE_POINTS:'DELETE_POINTS',
  SELECT_POINT:'SELECT_POINT',
  SELECT_UPDATE_POINT:'SELECT_UPDATE_POINT',
  SELECT_POINT_HIDE:'SELECT_POINT_HIDE',

  SET_ROUTES:'SET_ROUTES',
  ADD_ROUTES:'ADD_ROUTES',
  REMOVE_ROUTES:'REMOVE_ROUTES',
  // UPDATE_ROUTES:'UPDATE_ROUTES',


  SET_PROGRAM_FUNCTIONALITY_LIST: 'SET_PROGRAM_FUNCTIONALITY_LIST',
  REQUEST_LOGIN: 'REQUEST_LOGIN',
  REQUEST_FETCH_USER_DATA: 'REQUEST_FETCH_USER_DATA',
  REQUEST_UPDATE_USER_DATA: 'REQUEST_UPDATE_USER_DATA',

  REQUEST_FETCH_VENUES: 'REQUEST_FETCH_VENUES',
  REQUEST_FETCH_VENUE_DETAILS: 'REQUEST_FETCH_VENUE_DETAILS',
  REQUEST_ADD_VENUES: 'REQUEST_ADD_VENUES',
  REQUEST_FETCH_LAYERS: 'REQUEST_FETCH_LAYERS',
  REQUEST_FETCH_LAYER_DETAILS: 'REQUEST_FETCH_LAYER_DETAILS',
  REQUEST_ADD_LAYER: 'REQUEST_ADD_LAYER',
  REQUEST_UPDATE_LAYER: 'REQUEST_UPDATE_LAYER',
  REQUEST_DELETE_LAYER: 'REQUEST_DELETE_LAYER',
  REQUEST_FETCH_POINTS: 'REQUEST_FETCH_POINTS',
  REQUEST_FETCH_LAYER_POINTS: 'REQUEST_FETCH_LAYER_POINTS',
  REQUEST_FETCH_VENUE_POINTS: 'REQUEST_FETCH_VENUE_POINTS',
  REQUEST_ADD_POINTS: 'REQUEST_ADD_POINTS',
  // REQUEST_UPDATE_POINTS: 'REQUEST_UPDATE_POINTS',
  REQUEST_DELETE_POINTS: 'REQUEST_DELETE_POINTS',

  REQUEST_FETCH_ROUTES:'REQUEST_FETCH_ROUTES',
  REQUEST_FETCH_VENUE_ROUTES:'REQUEST_FETCH_VENUE_ROUTES',
  REQUEST_UPDATE_ROUTES:'REQUEST_UPDATE_ROUTES',
  REQUEST_GENERATE_ROUTES:'REQUEST_GENERATE_ROUTES',
  REQUEST_UPDATE_LINES:'REQUEST_UPDATE_LINES',
  REQUEST_FETCH_PATH:'REQUEST_FETCH_PATH',
  REQUEST_FETCH_ALL_LINES:'REQUEST_FETCH_ALL_LINES',
  REQUEST_FETCH_EXHIBITORS:'REQUEST_FETCH_EXHIBITORS',


}
