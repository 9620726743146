import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, matchPath, resolvePath } from 'react-router'
import { createSelector } from 'reselect'

import { NavLink, useNavigate, matchRoutes, useLocation } from "react-router-dom";

import Layout from 'antd/lib/layout'
import AntIcon from 'antd/lib/icon'
import Menu from 'antd/lib/menu'

import I from 'immutable'

import './sidebar.less'

import { STRINGS } from '../../config/strings'
import CustomIcon from './Icon'

import { trimPath, isFunction, isMobile, setCookies } from '../../helpers/utils'
import { MENU_SIDER_WIDTH, MENU_SIDER_COLLAPSED_WIDTH, APP_HEADER_HEIGHT } from '../../config/constants'
import { SIDEBAR_ICONS } from '../../helpers/images'
import { Button } from 'antd';


const { Sider } = Layout
const { SubMenu } = Menu;

const theme = 'light'
const MENU_ITEMS = I.fromJS([
  {
    id: '0',
    label: STRINGS.MENU.HOME,
    path: '/',
    match: /\//,
    icon: SIDEBAR_ICONS.HOME,
    visible: true,
    available: true,
    ids: 0
  },
  {
    id: '110',
    label: STRINGS.MENU.VENUES,
    path: '/venues',
    match: /\/venues/,
    // icon: SIDEBAR_ICONS.VENUE,
    visible: true,
    available: true,
    ids: 0
  },
  {
    id: '111',
    label: STRINGS.MENU.LAYERS,
    // path: '/layers',
    path: vid => {
      if (!!!vid) {
        return null;
      }
      return (`/venues/${vid}/layers`)
    },
    // match: /\/layers/,
    match: /^\/venues\/(\d+)\/layers/,
    icon: SIDEBAR_ICONS.LAYERS,
    visible: true,
    available: true,
    ids: 1,
  },
  {
    id: '112',
    label: STRINGS.MENU.POINTS,
    path: (vid, layer_id) => {
      if (!!!vid || !!!layer_id) {
        return null;
      }
      return (`/venues/${vid}/layers/${layer_id}/points`)
    },
    match: /^\/venues\/(\d+)\/layers\/(\d+)\/points/,
    icon: SIDEBAR_ICONS.POINTS,
    visible: true,
    available: true,
    ids: 2,
  },
  {
    id: '113',
    label: STRINGS.MENU.ROUTES,
    path: (vid, layer_id) => {
      if (!!!vid || !!!layer_id) {
        return null;
      }
      return (`/venues/${vid}/layers/${layer_id}/routes`)
    },
    match: /^\/venues\/(\d+)\/layers\/(\d+)\/routes/,
    icon: SIDEBAR_ICONS.ROUTES,
    visible: true,
    available: true,
    ids: 2,
  },
  {
    id: '114',
    label: STRINGS.MENU.PATH_FINDER,
    path: (vid, layer_id) => {
      console.log(!!vid, !!layer_id);
      if (!!!vid || !!!layer_id) {
        return null;
      }
      return (`/venues/${vid}/layers/${layer_id}/pathfinder`)
    },
    match: /^\/venues\/(\d+)\/layers\/(\d+)\/pathfinder/,
    icon: SIDEBAR_ICONS.ROUTES,
    visible: true,
    available: true,
    ids: 2,
  },

  // {
  //   id: '1',
  //   label: STRINGS.MENU.EVENT,
  //   path: '/event',
  //   match: /\/event/,
  //   icon: 'flag',
  //   visible: true,
  //   available: true,
  //   children: [
  //     {
  //       id: '1.1',
  //       label: STRINGS.MENU.EVENT_INFO,
  //       path: id => {
  //         navigateToUrl(`/event/${id}/info`)
  //       },
  //       match: /\/event\/.+\/info/,
  //       icon: 'info-circle',
  //       visible: true,
  //       available: true,
  //     },
  //   ]
  // },
])

const selectStore = createSelector(
  ({ app }) => ({
    user: app.get('user'),
    pathname: ''//trimPath(router.location.pathname)
  }),
  ({ event, pathname }) => {
    let activeMenu = MENU_ITEMS.get(0)
    let defaultOpenKeys = I.List()

    // if (pathname !== '/') {
    //   MENU_ITEMS.forEach(item => {
    //     if (item.has('children')) {
    //       item.get('children').forEach(childMenu => {
    //         if (childMenu.get('match').test(pathname)) {
    //           defaultOpenKeys = defaultOpenKeys.push(item.get('id'))
    //           activeMenu = childMenu
    //         }
    //       })
    //     } else {
    //       if (item.get('match').test(pathname)) {
    //         activeMenu = item
    //       }
    //     }
    //   })
    // }
    return {
      event,
      menuItems: MENU_ITEMS.filter(item => item.get('visible')),
      activeMenuId: activeMenu.get('id'),
      defaultOpenKeys,
      pathname
    }
  })

const MenuIcon = props => <img src={props.type} style={{ width: 20 }} />

const findMenuById = (menuItems, id) => {
  let item = null
  menuItems.forEach(menu => {
    if (menu.get('id') === id) {
      item = menu
    } else {
      if (menu.has('children')) {
        menu.get('children').forEach(child => {
          if (child.get('id') === id) {
            item = child
          }
        })
      }
    }
  })
  return item
}

const renderMenuItem = item => (

  <Menu.Item key={item.get('id')} className="sideMenu_vertical">
    {/* <NavLink to={item.get('a','/')}> */}
    <span>
      <img
        // component={SIDEBAR_ICONS.HOME}
        src={item.get('icon1')}
        style={{ marginRight: 0, fontSize: 18 }} />

      {item.get('label')}
    </span>
    {/* </NavLink> */}
  </Menu.Item>
)

const Sidebar = (props) => {

  let navigate = useNavigate()

  const { sidebarCollapsed } = props
  const { menuItems, defaultOpenKeys } = useSelector(selectStore)
  const [openKeys, setOpenKeys] = useState(defaultOpenKeys)
  const [activeMenuId, setActiveMenuId] = useState()
  const [venueId, setVenueId] = useState()
  const [layerId, setLayerId] = useState()
  const [pointId, setPointId] = useState()


  const { pathname } = useLocation()

  // let venueId
  // let layerId
  // let pointId


  useEffect(() => {
    let activeMenu = MENU_ITEMS.get(0)
    let defaultOpenKeys = I.List()



    // if (pathname !== '/') {
    MENU_ITEMS.forEach(item => {
      if (item.has('children')) {
        item.get('children').forEach(childMenu => {
          if (childMenu.get('match').test(pathname)) {
            defaultOpenKeys = defaultOpenKeys.push(item.get('id'))
            activeMenu = childMenu
          }
        })
      } else {
        if (item.get('match').test(pathname)) {
          activeMenu = item
        }
      }

    })

    // console.log(activeMenu.toJS())
    setActiveMenuId(activeMenu.get('id'))
    // }

    if (getParams('/venues/:venueId')) {
      let c = getParams('/venues/:venueId')
      setParams(c.params)
    } else if (getParams('/venues/:venueId/layers')) {
      let c = getParams('/venues/:venueId/layers')
      setParams(c.params)
    } else if (getParams('/venues/:venueId/layers/:layerId')) {
      let c = getParams('/venues/:venueId/layers/:layerId')
      setParams(c.params)
    } else if (getParams('/venues/:venueId/layers/:layerId/pathfinder')) {
      let c = getParams('/venues/:venueId/layers/:layerId/pathfinder')
      setParams(c.params)
    } else if (getParams('/venues/:venueId/layers/:layerId/points')) {
      let c = getParams('/venues/:venueId/layers/:layerId/points')
      setParams(c.params)
    } else if (getParams('/venues/:venueId/layers/:layerId/routes')) {
      let c = getParams('/venues/:venueId/layers/:layerId/routes')
      setParams(c.params)
    } else if (getParams('/venues/:venueId/layers/:layerId/points/:pointId')) {
      let c = getParams('/venues/:venueId/layers/:layerId/points/:pointId')
      setParams(c.params)
    } else {
      setParams({})
    }

  }, [pathname])

  const setParams = (params) => {
    setVenueId(params.venueId)
    setLayerId(params.layerId)
    setPointId(params.pointId)
  }


  const getParams = useCallback((path) => {
    return matchPath({ path }, pathname) ?? null
  }, [pathname])

  const onMenuClick = useCallback((e) => {

    if (e.key !== activeMenuId) {
      const clickedMenuItem = findMenuById(menuItems, e.key)
      if (clickedMenuItem) {
        const path = clickedMenuItem.get('path')

        if (isFunction(path)) {
          let p = path(venueId, layerId, pointId)
          // console.log(venueId, p, path);
          if (p) {
            navigate(p)
          }
        } else {
          // navigateToUrl(path)
          navigate(path)
        }
        if (sidebarCollapsed) {
          setOpenKeys(I.List())
        }
      }
    }
  }, [pathname, activeMenuId, venueId, layerId, pointId])


  return (
    <Sider
      className="__sidebar"
      theme={theme}
      collapsedWidth={MENU_SIDER_COLLAPSED_WIDTH}
      trigger={null}
      collapsed={sidebarCollapsed}
      width={MENU_SIDER_WIDTH}
      style={isMobile() ? {
        position: 'absolute',
        top: APP_HEADER_HEIGHT,
        left: 0,
        bottom: 0,
        zIndex: 100
      } : {}}
    >
      <Menu
        className="__sidebar-menu"
        theme={theme}
        selectedKeys={[activeMenuId]}
        openKeys={openKeys.toJS()}
        mode="inline"
        onClick={onMenuClick}
      >
        {menuItems.valueSeq().map(item => {
          if (item.get('ids') <= [venueId, layerId, pointId].filter(x => !!x).length) {
            if (item.has('children')) {
              return <SubMenu
                key={item.get('id')}
                onTitleClick={({ key }) => {
                  if (openKeys.includes(key)) {
                    setOpenKeys(openKeys.delete(openKeys.findIndex(item => item === key)))
                  } else {
                    setOpenKeys(openKeys.push(key))
                  }
                }}
                title={
                  <span>
                    <MenuIcon type={item.get('icon')} />
                    <span>{item.get('label')}</span>
                  </span>
                }>
                {item.get('children').valueSeq().map(subItem => {
                  return renderMenuItem(subItem)
                })}
              </SubMenu>
            } else {

              return renderMenuItem(item)
            }
          }
        })}

      </Menu>

      <div className='flex p-1 absolute bottom-0 right-0 left-0 opacity-60'>
        <Button className={`${props.mapType == 1 ? '__bt1' : 'button_prime'} h-auto`}
          onClick={() => {
            setCookies('mapType', props.mapType == 1 ? 0 : 1)
            props.setMapType(props.mapType == 1 ? 0 : 1)
          }}
          style={{ border: '1px solid white', fontSize: 12, borderRadius: 3, padding: '2px 5px' }}
        >SAT</Button>
      </div>
    </Sider >
  )
}


export default Sidebar