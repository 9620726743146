import I from 'immutable'


import { asImmutable, removeListItem, updateListItem } from '../../helpers/utils'

import VenueActionTypes from './VenueActionTypes'

const initialState = I.Map({

  // venues: I.Map(),
  layers1: I.fromJS([
    {
      layer_id: 1584084022571,
      layer_name: 'Layer 1',
      layer_floor: 'Floor 1',
      layer_rotation: 90,
      layer_opacity: 1,
      layer_latitude_1: 15.763530518150958,
      layer_longitude_1: -7.4790287017822275,
      layer_latitude_2: 15.76714435372622,
      layer_longitude_2: -7.472891807556153,
      layer_image: 'https://www.roomsketcher.com/wp-content/uploads/2016/10/1-Bedroom-Floor-Plan-600x450.jpg'
    },
    {
      layer_id: 1584084022574,
      layer_name: 'Layer 2',
      layer_floor: 'Floor 1',
      layer_rotation: 0,
      layer_opacity: 1,
      layer_latitude_1: 7.449624260197829,
      layer_longitude_1: -18.369140625000004,
      layer_latitude_2: -0.21972602392080884,
      layer_longitude_2: -10.986328125000002,
      layer_image: 'https://www.roomsketcher.com/wp-content/uploads/2016/10/1-Bedroom-Floor-Plan-600x450.jpg'
    },
    {
      layer_id: 1584084031445,
      layer_name: 'Layer 3',
      layer_floor: 'Floor 1',
      layer_rotation: 0,
      layer_opacity: 1,
      layer_latitude_1: 7.885147283424331,
      layer_longitude_1: 9.052734375000002,
      layer_latitude_2: -6.664607562172573,
      layer_longitude_2: 28.4765625,
      layer_image: 'https://www.iosea.co.uk/wp-content/uploads/Drumgarry-Ardvaser-Floor-1-004.jpg'
    }
  ]),
  points1: I.fromJS([
    {
      point_visible: true,
      point_subtitle: 'Software',
      point_title: 'Corbel Business',
      point_id: 1584084022570,
      point_marker_display: '',
      point_key: 'CORB',
      point_searchable: true,
      point_details: 'Development',
      point_clickable: true,
      point_priority: 2,
      point_icon_url: '',
      point_keywords: 'software, IT, ',
      point_latitude: 15.765833055113362,
      point_longitude: -7.475574016571046,

      point_floor: 1
    },
    {
      point_longitude: -7.474790811538697,
      point_visible: true,
      point_subtitle: 'Software',
      point_title: 'ABC 1',
      point_id: 1584084022571,
      point_marker_display: '',
      point_key: 'CORB',
      point_searchable: true,
      point_details: 'Development',
      point_clickable: true,
      point_priority: 2,
      point_icon_url: '',
      point_keywords: 'software, IT, ',
      point_latitude: 15.76579691684265,
      point_floor: 1
    },
    {
      point_longitude: -7.476802468299867,
      point_visible: true,
      point_title: 'Bibin 12',
      point_id: 1652437097720,
      point_marker_display: '',
      point_searchable: true,
      point_clickable: true,
      point_priority: 3,
      point_icon_url: '',
      point_latitude: 15.766132486251797,
      point_floor: 1
    },
    {
      point_id: 1653033887499,
      point_title: 'Sachin',
      point_latitude: 15.765817567283845,
      point_longitude: -7.477896809577943
    },
    {
      point_id: 1653033890227,
      point_title: 'Dravid',
      point_latitude: 15.764387519265123,
      point_longitude: -7.477859258651734
    },
    {
      point_id: 1653033906354,
      point_title: 'Sehwang',
      point_latitude: 15.76489862061665,
      point_longitude: -7.476314306259156
    },
    {
      point_id: 1653033922340,
      point_title: 'Ganguly',
      point_latitude: 15.76490378325001,
      point_longitude: -7.475820779800416
    },
    {
      point_id: 1653033923595,
      point_title: 'Lara',
      point_latitude: 15.76487797008196,
      point_longitude: -7.4747318029403695
    },
    {
      point_id: 1653033941883,
      point_title: 'Dhoni',
      point_latitude: 15.765807242063515,
      point_longitude: -7.473819851875306
    },
    {
      point_id: 1653033948746,
      point_title: 'Kumble',
      point_latitude: 15.765440696401184,
      point_longitude: -7.473514080047608
    },
    {
      "point_id": 1653034063235,
      "point_title": 'exit 1',
      "point_latitude": 15.76416036269576,
      "point_longitude": -7.478594183921815
    },
    {
      point_longitude: -7.478567361831666,
      point_title: 'exit 2',
      point_id: 1653034064475,
      point_latitude: 15.764439145722717
    },
    {
      "point_id": 1653034079571,
      "point_title": 'exit 3',
      "point_latitude": 15.764888295349566,
      "point_longitude": -7.478519082069398
    },
    {
      point_longitude: -7.47722625732422,
      point_title: 'ZA',
      point_id: 1653303858318,
      point_latitude: 15.765750453342257
    },
    {
      point_longitude: -7.47644305229187,
      point_title: 'ZX',
      point_id: 1653303860014,
      point_latitude: 15.765791754232014
    }
  ]),

  routes1: I.fromJS([
    {
      id: 1653126764460,
      data: [],
      data1: [
        // {
        //   route_id: 1653126764460,
        //   route_latitude: 15.764150037391119,
        //   route_longitude: -7.478417158126832
        // },
        // {
        //   route_id: 1653126766389,
        //   route_latitude: 15.764965734839935,
        //   route_longitude: -7.4783045053482065
        // },
        {
          route_id: 1653126767989,
          route_latitude: 15.764965734839935,
          route_longitude: -7.477312088012696
        },
        // {
        //   route_id: 1653126778868,
        //   route_latitude: 15.765719477669434,
        //   route_longitude: -7.477236986160279
        // },
        {
          route_id: 1653126782837,
          route_latitude: 15.766416429164584,
          route_longitude: -7.477161884307862
        },
        {
          route_id: 1653126785701,
          route_latitude: 15.766431916948402,
          route_longitude: -7.476426959037782
        },
        {
          route_id: 1653126788222,
          route_latitude: 15.765420045921642,
          route_longitude: -7.47645914554596
        },
        // {
        //   route_id: 1653126790620,
        //   route_latitude: 15.765420045921642,
        //   route_longitude: -7.475160956382752
        // },
        {
          route_id: 1653126795149,
          route_latitude: 15.765420045921642,
          route_longitude: -7.473675012588502
        }
      ]
    },
    {
      id: 1654500551357,
      data: [],
      data1: [
        {
          route_id: 1654500551357,
          route_latitude: 15.765580087083169,
          route_longitude: -7.478363513946533
        },
        {
          route_id: 1654500553245,
          route_latitude: 15.765693664605122,
          route_longitude: -7.47547745704651
        }
      ]
    },
    {
      "id": 16531267644601,
      "data": [

        {
          "route_id": "1",
          "route_latitude": 15.764965734839935,
          "route_longitude": -7.477312088012696
        },

        {
          "route_id": "2",
          "route_latitude": 15.766416429164584,
          "route_longitude": -7.477161884307862
        },
        {
          "route_id": "3",
          "route_latitude": 15.766431916948402,
          "route_longitude": -7.476426959037782
        },
        {
          "route_id": "4",
          "route_latitude": 15.765420045921642,
          "route_longitude": -7.47645914554596
        },
        {
          "route_id": "5",
          "route_latitude": 15.765420045921642,
          "route_longitude": -7.473675012588502
        }
      ]
    },
    {
      "id": "785367845",
      data: [{
        "route_id": "11",
        "route_latitude": 15.765580087083169,
        "route_longitude": -7.478363513946533
      },
      {
        "route_id": "12",
        "route_latitude": 15.765693664605122,
        "route_longitude": -7.47547745704651
      },
      {
        "route_id": "13",
        "route_latitude": 15.764420045921642,
        "route_longitude": -7.472675012588502
      }
      ]
    }
    // {
    //   id: 1653285581334,
    //   data: [
    //     {
    //       route_id: 1653285581334,
    //       route_latitude: 15.765538786150348,
    //       route_longitude: -7.478250861167909
    //     },
    //     {
    //       route_id: 1653285582790,
    //       route_latitude: 15.765595574930828,
    //       route_longitude: -7.475611567497254
    //     }
    //   ]
    // }
  ])

})

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {

    // case VenueActionTypes.SET_VENUES:
    //   return state.set('venues', asImmutable(payload.data));

    case VenueActionTypes.SET_VENUES_DETAILS:
      return state.set('venues', asImmutable(payload.data));
    // .set('initialLoad', false)


    case VenueActionTypes.SET_LAYERS:
      return state.setIn(['layers'], asImmutable(payload.data));

    case VenueActionTypes.ADD_LAYER: {
      let items = state.getIn(['layers'], I.List())
      return state//.setIn(['layers'], items.push(asImmutable(payload.data)))
    }

    case VenueActionTypes.UPDATE_LAYER: {
      let item = state.getIn(['layers'], I.List())
      item = updateListItem(item, asImmutable(payload.item), false, 'layer_id');
      return state.set('layers', item)
    }

    case VenueActionTypes.SET_POINTS: {
      return state.set('points', asImmutable(payload.data))
    }

    case VenueActionTypes.ADD_POINTS: {
      let items = state.getIn(['points'], I.List())
      return state.set('points', items.push(asImmutable(payload.data)))
    }
    case VenueActionTypes.UPDATE_POINTS: {
      let items = state.getIn(['points'], I.List())
      items = updateListItem(items, asImmutable(payload.data), false, 'point_id');
      return state.set('points', items)
    }
    case VenueActionTypes.DELETE_POINTS: {
      const { id } = payload

      let st = state
      if (state.getIn(['point', 'point_id']) === id) {
        st = st.remove('point')
      }
      return st.setIn(['points'], removeListItem(st.get('points'), id, 'point_id'))
    }
    case VenueActionTypes.SELECT_POINT: {
      return state.setIn(['point'], asImmutable(payload.data))
    }
    case VenueActionTypes.SELECT_UPDATE_POINT: {
      let data = asImmutable(payload.data)

      let item = state.getIn(['point'], I.Map())
      if (item.get('point_id') === data.get('point_id')) {
        item = item.mergeWith((oldVal, newVal) => {
          return newVal
        }, data)
      }
      return state.setIn(['point'], item)
    }
    case VenueActionTypes.SELECT_POINT_HIDE: {
      return state.remove('point')
    }





    case VenueActionTypes.SET_ROUTES: {
      return state.set('routes', asImmutable(payload.data))
    }

    case VenueActionTypes.REMOVE_ROUTES: {
      const { id } = payload
      return state.setIn(['routes'], removeListItem(state.get('routes'), asImmutable({ 'route_id': id }), 'route_id'))
    }
    // case VenueActionTypes.SET_ROUTES: {
    //   return state.set('routes', asImmutable(payload.data))
    // }


    case VenueActionTypes.SET_MARKERS: {
      return state.set('markers', (asImmutable(payload.items)))
    }
    case VenueActionTypes.UPDATE_MARKER: {
      let item = state.getIn(['markers'], I.List())
      return state.set('markers', updateListItem(item, asImmutable(payload.item), false))
    }
    case VenueActionTypes.SET_POLY_LINES: {
      return state.set('lines', (asImmutable(payload.items)))
    }

    // case VenueActionTypes.ADD_POLY_LINES: {
    //   let item = state.getIn(['lines'], I.List())
    //   let data = {
    //     id: new Date().getTime(),
    //     data: payload.item
    //   }
    //   return state.set('lines', item.push(asImmutable(data)))
    // }
    case VenueActionTypes.UPDATE_LINES: {
      // let item = state.getIn(['lines'], I.List())
      // let data = {
      //   id: new Date().getTime(),
      //   data: payload.item
      // }
      return state.set('lines', payload.item)
    }




    case VenueActionTypes.ADD_VENUE: {
      let item = state.getIn(['venues'], I.List())
      let data = {
        ...payload.item,
        id: new Date().getTime()
      }
      console.log("data", data);
      return state.set('venues', item.push((data)))
    }

    case VenueActionTypes.ADD_ROUTES: {
      let items = state.getIn(['routes'], I.List()).push()
      return state.set('routes', items.push(asImmutable(payload.data)))
    }




    case VenueActionTypes.SET_EVENT_TYPES_LIST:
      return state.set('eventTypes', payload.items)
    case VenueActionTypes.SET_SOCIAL_MEDIAS_LIST:
      return state.set('socialMedias', payload.items)
    case VenueActionTypes.SET_PROGRAM_FUNCTIONALITY_LIST:
      return state.set('programFunctionalities', payload.items)
    default:
      return state
  }
}
