import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@ant-design/icons'
import Tooltip from 'antd/lib/tooltip'
// import RIcon from 'react-icons-kit'

class CustomIcon extends React.Component {
  render() {
    let wrapperStyle = Object.assign({}, this.props.wrapperStyle)
    let iconStyle = Object.assign({ display: 'flex', alignItems: 'center' }, this.props.iconStyle)

    let labelStyle = {}

    if (this.props.iconColor) {
      iconStyle['color'] = this.props.iconColor
    }
    if (this.props.labelColor) {
      labelStyle['color'] = this.props.labelColor
    }

    let label = (
      // <Tooltip placement={this.props.tooltipPlacement || 'top'} title={this.props.tooltip || ''}>
      <span style={labelStyle}>{this.props.label}</span>
      // </Tooltip>
    )

    let labelLeft = null
    let labelRight = null

    if (this.props.label) {
      if (this.props.reverse) {
        labelLeft = label
        labelStyle['marginRight'] = 4
      } else {
        labelRight = label
        labelStyle['marginLeft'] = 4
      }
    }

    labelStyle = Object.assign(labelStyle, this.props.labelStyle)

    return (
      <span className={'__icon ' + (this.props.className || '')} style={wrapperStyle} onClick={this.props.onClick}>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {labelLeft}
          <Tooltip title={this.props.tooltip}>
            {typeof this.props.icon === 'string' ? (
              <Icon icon={this.props.icon} style={iconStyle} size={this.props.size || 24} />
            ) : (
                <Icon
                  component={
                    this.props.component
                    // () => <RIcon icon={this.props.icon} size={this.props.size || 24}/>
                  }
                  style={iconStyle}
                  size={this.props.size}

                />
              )}
          </Tooltip>
          {labelRight}
        </span>
      </span>
    )
  }
}

CustomIcon.propTypes = {
  // icon: PropTypes.object,
  wrapperStyle: PropTypes.object
}

export default CustomIcon
