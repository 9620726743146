import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Layout from 'antd/lib/layout'
import AntIcon from 'antd/lib/icon'
import Typography from 'antd/lib/typography'
import Dropdown from 'antd/lib/dropdown'
import Menu from 'antd/lib/menu'
import Avatar from 'antd/lib/avatar'
import Select from 'antd/lib/select'
import Button from 'antd/lib/button'
import Tooltip from 'antd/lib/tooltip'

import { UserOutlined, MoreOutlined, MenuOutlined, LogoutOutlined } from '@ant-design/icons';


import I from 'immutable'



import { AppLogo, APP_HEADER_HEIGHT } from '../../config/constants';
import { COLORS } from '../../config/colors';

import '../../styles/header.less'

import { navigateToUrl } from '../../helpers/StoreHelper'
import { getUserEmail, getUserName } from '../../helpers/utils'
import { useNavigate } from 'react-router'

const { Header, Content } = Layout
const { Title } = Typography


const mapState = createSelector(
  ({ app }) => ({
    user: app.get('user'),
  }), (user) => {
    // console.log(user)
    return user;
    // return {
    //   userName: user.get('first_name'),
    //   userEmail: user.get('email'),
    //   activeEvent: "event",
    //   userEvents: I.LIst()

    // }
  }
)

export default (props) => {
  // const { user } = useSelector(mapState)
  const userName = getUserName()
  const userEmail = getUserEmail()
  const userEvents = I.List()
  let activeEvent;

  let navigate = useNavigate()

  // console.log(userName);
  const dispatch = useDispatch()
  const clearActiveEvent = useCallback(() => {
    // dispatch(EventActionCreators.setEventData(null))
    navigate('/logout')
  }, [dispatch])


  return (
    <Header style={{
      height: APP_HEADER_HEIGHT,
      padding: 0,
      backgroundColor: COLORS.HEADER_BG,
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
      msUserSelect: 'none',
      borderBottom: '1px solid #eee'
    }}>
      <Row >
        <Col span={12}>
          <div className='ml-5 flex' style={{ alignItems: 'center' }}>
            <MenuOutlined
              className="__app-sidebar-toggle-icon mr-5"
              style={{ fontSize: 16 }}
              onClick={() => {
                props.setSidebarCollapsed(!props.sidebarCollapsed)
              }} />

            <img src={AppLogo} style={{ width: 200, height: APP_HEADER_HEIGHT, marginLeft: 8 }} />
          </div>
        </Col>
        <Col span={12}>
          <Row type="flex" justify="end">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  width: '200px',
                  margin: 0,
                  paddingRight: "20px"
                }}
              >
                {userEvents.size ?
                  <Select
                    value={activeEvent.get('id')}
                    style={{ width: '100%', marginRight: 4 }}
                    onChange={id => {
                      navigateToUrl(`/event/${id}/`)
                    }}
                  >
                    {userEvents.map(event => {
                      if (event.get('id') === activeEvent.get('id')) {
                        event = activeEvent
                      }
                      return (
                        <Select.Option
                          value={event.get('id')}
                          key={event.get('id')}
                        >
                          {event.get('logo') ?
                            <img src={event.get('logo')} alt='' width={24} height={24} /> :
                            <AntIcon type="deployment-unit" style={{ fontSize: 24, color: '#ccc' }} />
                          }&nbsp;
                          {event.get('short_name') || event.get('name')}
                        </Select.Option>
                      )
                    })}
                  </Select> : null
                }
                {/* <Tooltip title={userEvents.size ? '' : 'Create New Event'}>
                  <Button
                    type="primary"
                    shape={userEvents.size ? "circle" : "round"}
                    onClick={() => {
                      clearActiveEvent()
                      navigateToUrl('/event/new/')
                    }}
                  >
                    <AntIcon type="plus" style={{ color: COLORS.WHITE }} />
                    {userEvents.size ? null : ' Create New Event'}
                  </Button>
                </Tooltip> */}
              </div>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 'logout',
                      label: (
                        <a  onClick={clearActiveEvent}>
                          Logout
                        </a>
                      ),
                      icon: <LogoutOutlined />
                    },
                  ]
                }}
               
              >
                <div style={{ marginRight: 12, height: APP_HEADER_HEIGHT }} href="#" className="__header-user-trigger ">
                  <span style={{ textAlign: 'right', marginRight: 4 }}>{userName}<br /><span style={{ color: '#ccc' }}>{userEmail}</span></span>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar size="medium" icon={<UserOutlined />} />
                    <MoreOutlined style={{ marginLeft: 2, fontSize: 22 }} />
                  </span>
                </div>
              </Dropdown>
            </div>
          </Row>
        </Col>
      </Row >
    </Header >
  )
}
