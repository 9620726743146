import { call, put, takeEvery, takeLatest, all } from "redux-saga/effects";

import I from 'immutable'

import VenueActionTypes from "./VenueActionTypes";
import VenueActionCreators from "./VenueActionCreators";

import { TOAST } from "../../helpers/NotificationHelper";
import ApiHelper from "../../helpers/ApiHelper";
import { asImmutable, isFunction } from "../../helpers/utils";
import AppActionCreators from "../app/AppActionCreators";
import { HAS_API } from "../../config/constants";



function* fetchVenues(action) {
    const { cb } = action.payload

    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.fetchVenues)

        if (resData) {
            // if (resData.data.event_types) {
            //     yield put(VenueActionCreators.setEventTypesList(I.fromJS(resData.data.event_types)))
            // }
            // if (resData.data.social_medias) {
            //     yield put(VenueActionCreators.setSocialMediasList(I.fromJS(resData.data.social_medias)))
            // }
            // if (resData.data.program_functionalities) {
            //     yield put(VenueActionCreators.setProgramFunctionalityList(I.fromJS(resData.data.program_functionalities)))
            // }
        }

        // yield put(VenueActionCreators.setVenues(I.fromJS(resData)))
        yield put(AppActionCreators.setVenues(I.fromJS(resData)))
        // yield put(VenueActionCreators.setUserData(I.fromJS(resData.user)))


    } catch (e) {
        console.log(e)
        TOAST.error(e.response.message)
        if (isFunction(cb)) {
            yield call(cb, false, e)
        }
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}
function* fetchVenueDetails(action) {
    const { id, cb } = action.payload
    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.fetchVenueDetails, id)
        // console.log('fetchVenueDetails', resData)

        if (resData) {
            // if (resData.data.event_types) {
            //     yield put(VenueActionCreators.setEventTypesList(I.fromJS(resData.data.event_types)))
            // }
            // if (resData.data.social_medias) {
            //     yield put(VenueActionCreators.setSocialMediasList(I.fromJS(resData.data.social_medias)))
            // }
            // if (resData.data.program_functionalities) {
            //     yield put(VenueActionCreators.setProgramFunctionalityList(I.fromJS(resData.data.program_functionalities)))
            // }
        }


        if (resData) {
            yield put(VenueActionCreators.setVenueDetails(resData))
            if (isFunction(cb)) {
                yield call(cb, { status: true })
            }
        }

        //// yield put(VenueActionCreators.setVenues(I.fromJS(resData)))
        // yield put(VenueActionCreators.setVenueDetails(I.fromJS(resData)))
        //// yield put(VenueActionCreators.setUserData(I.fromJS(resData.user)))

    } catch (e) {
        // log(e)
        TOAST.error(e.response.message)
        if (isFunction(cb)) {
            yield call(cb, { status: true })
        }
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}

function* createVenues(action) {
    const { data, isNew, cb } = action.payload
    let resData;
    try {
        yield put(AppActionCreators.showLoader())

        resData = yield call(ApiHelper.createVenues, data, isNew)

        if (resData) {
            // resData = I.fromJS(resData)
            yield put(AppActionCreators.setVenues(resData))
            if (isFunction(cb)) {
                yield call(cb, { status: true, data: resData })
            }
        }


    } catch (e) {
        TOAST.error(e.message)
        if (isFunction(cb)) {
            // data = {
            //      e.response
            // }
            yield call(cb, { status: false, ...e.response })
        }
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}

function* fetchLayers(action) {
    const { id, cb } = action.payload
    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.fetchLayers, id)
        yield put(VenueActionCreators.setLayers(I.fromJS(resData)))
    } catch (e) {
        console.log(e, action.payload.id)
        TOAST.error(e.response.message)
        if (isFunction(cb)) {
            yield call(cb, false, { ...e })
        }
    } finally {
        yield put(AppActionCreators.hideLoader())

    }
}

function* fetchLayerDetails(action) {
    const { id, layerId, cb } = action.payload
    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.fetchLayerDetails, id, layerId)
        // yield put(VenueActionCreators.setVenues(I.fromJS(resData)))
        // yield put(VenueActionCreators.setLayers(I.fromJS(resData)))
        // yield put(VenueActionCreators.setUserData(I.fromJS(resData.user)))
        if (isFunction(cb)) {
            yield call(cb, true, resData)
        }
    } catch (e) {
        console.log(e, action.payload.id)
        TOAST.error(e.response.message)
        if (isFunction(cb)) {
            yield call(cb, false, { ...e })
        }
    } finally {
        yield put(AppActionCreators.hideLoader())

    }
}

function* createLayer(action) {
    const { id, data, image, cb } = action.payload

    try {
        yield put(AppActionCreators.showLoader())
        if (HAS_API) {
            const resData = yield call(ApiHelper.createLayers, id, data, image)
            console.log('createLayer', id, resData)

            yield put(VenueActionCreators.setLayers(resData))
        } else {
            let dt = data
            dt.id = 123
            yield put(VenueActionCreators.addLayer(dt))
        }
        if (isFunction(cb)) {
            yield call(cb, { status: true })
        }

    } catch (e) {
        if (isFunction(cb)) {
            yield call(cb, { status: false, errors: e.response.errors })
        }
        TOAST.error(e.response.message)
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}
function* updateLayer(action) {
    const { venueId, id, item, image, cb } = action.payload
    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.updateLayer, venueId, id, item, image)

        yield put(VenueActionCreators.setLayers(resData))

        if (isFunction(cb)) {
            yield call(cb, true)
        }

    } catch (e) {
        console.log(e)
        if (isFunction(cb)) {
            yield call(cb, { status: false, errors: e.response.errors })
        }
        TOAST.error(e.response.message)
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}
function* deleteLayer(action) {
    console.log('updateLayer', action)
    const { venueId, id, cb } = action.payload

    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.deleteLayer, venueId, id)
        console.log('updateLayer', id, resData)

        yield put(VenueActionCreators.setLayers(resData))

        if (isFunction(cb)) {
            yield call(cb, true)
        }

    } catch (e) {
        console.log(e)
        if (isFunction(cb)) {
            yield call(cb, false, e.response.errors)
        }
        TOAST.error(e.response.message)
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}



function* fetchPoints(action) {
    const { venueId, layerId, search, cb } = action.payload
    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.fetchPoints, venueId, layerId, search)
        yield put(VenueActionCreators.setPoints(asImmutable(resData)))

        if (isFunction(cb)) {
            yield call(cb, true, asImmutable(resData))
        }

    } catch (e) {
        console.log(e)
        if (isFunction(cb)) {
            yield call(cb, false, e)
        }
        TOAST.error(e.response.message)
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}

function* fetchLayerPoints(action) {
    const { venueId, layerId, search,type, cb } = action.payload
    try {
        const resData = yield call(ApiHelper.fetchPoints, venueId, layerId, search,type)
    
        if (isFunction(cb)) {
            yield call(cb, true, asImmutable(resData))
        }

    } catch (e) {
        console.log(e)
        if (isFunction(cb)) {
            yield call(cb, false, e)
        }
        TOAST.error(e.response.message)
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}

function* fetchVenuePoints(action) {
    const { venueId, search, cb } = action.payload
    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.fetchVenuePoints, venueId, search)
        yield put(VenueActionCreators.setPoints(asImmutable(resData)))

        if (isFunction(cb)) {
            yield call(cb, true, asImmutable(resData))
        }

    } catch (e) {
        console.log(e)
        if (isFunction(cb)) {
            yield call(cb, false, e)
        }
        TOAST.error(e.response.message)
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}


function* createPoints(action) {
    const { venueId, layerId, data, cb } = action.payload

    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.createPoints, venueId, layerId, data)

        // yield put(VenueActionCreators.addPoints(resData))

        if (isFunction(cb)) {
            yield call(cb, true, resData)
        }
    } catch (e) {
        console.log(e);
        if (isFunction(cb)) {
            yield call(cb, false, e.response.errors)
        }
        TOAST.error(e.response.message)
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}

function* updatePoints(action) {
    const { venueId, id, data, cb } = action.payload

    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.updatePoints, venueId, id, data)
        yield put(VenueActionCreators.updatePoints(resData))

        if (isFunction(cb)) {
            yield call(cb, { status: true })
        }
    } catch (e) {
        if (isFunction(cb)) {
            yield call(cb, { status: false, errors: e.response.errors })
        }
        TOAST.error(e.response.message)
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}

function* deletePoints(action) {
    const { venueId, layerId, pointId, cb } = action.payload

    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.deletePoints, venueId, layerId, pointId)
        // yield put(VenueActionCreators.deletePoints(layerId))

        if (isFunction(cb)) {
            yield call(cb, true)
        }
    } catch (e) {
        if (isFunction(cb)) {
            yield call(cb, false, e.response.errors)
        }
        TOAST.error(e.response.message)
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}


function* fetchVenueRoutes(action) {

    const { venueId, layerId, cb } = action.payload
    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.fetchVenueRoutes, venueId, layerId)
        
        if (isFunction(cb)) {
            yield call(cb, true, asImmutable(resData))
        }
    } catch (e) {
        console.log(e, venueId)
        if (isFunction(cb)) {
            yield call(cb, false, e)
        }
        TOAST.error(e.response.message)
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}

function* fetchRoutes(action) {

    const { venueId, layerId, cb } = action.payload
    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.fetchRoutes, venueId, layerId)
        yield put(VenueActionCreators.setRoutes(resData))

        if (isFunction(cb)) {
            yield call(cb, true)
        }
    } catch (e) {
        console.log(e, venueId)
        if (isFunction(cb)) {
            yield call(cb, false, e)
        }
        TOAST.error(e.response.message)
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}

function* updateRoutes(action) {
    const { venueId, layerId, data, cb } = action.payload

    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.updateRoutes, venueId, layerId, data)
        yield put(VenueActionCreators.setRoutes(resData))

        if (isFunction(cb)) {
            yield call(cb, true)
        }
    } catch (e) {
        if (isFunction(cb)) {
            yield call(cb, false, e.response.errors)
        }
        TOAST.error(e.response.message)
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}

function* generateRoutes(action) {
    const { venueId, layerId, cb } = action.payload

    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.generateRoutes, venueId, layerId)
        // yield put(VenueActionCreators.setRoutes(resData))

        if (isFunction(cb)) {
            yield call(cb, true)
        }
        TOAST.success(resData.message)
    } catch (e) {
        if (isFunction(cb)) {
            yield call(cb, false, e.response.errors)
        }
        TOAST.error(e.response.message)
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}

function* updateLines(action) {
    const { venueId, layerId, data, cb } = action.payload

    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.updateLines, venueId, layerId, data)
        // yield put(VenueActionCreators.setRoutes(resData))

        if (isFunction(cb)) {
            yield call(cb, true)
        }
        TOAST.success(resData.message)
    } catch (e) {
        if (isFunction(cb)) {
            yield call(cb, false, e.response.errors)
        }
        TOAST.error(e.response.message)
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}



function* fetchPath(action) {

    const { venueId, layerId, id1, id2, cb } = action.payload
    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.fetchPath, venueId, layerId, id1, id2)
        yield put(VenueActionCreators.setRoutes(resData))

        if (isFunction(cb)) {
            yield call(cb, true, asImmutable(resData))
        }
    } catch (e) {
        console.log(e, venueId)
        if (isFunction(cb)) {
            yield call(cb, false)
        }
        TOAST.error(e.response.message)
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}




function* fetchAllLines(action) {

    const { venueId, layerId, cb } = action.payload
    try {
        yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.fetchAllLines, venueId, layerId)
        yield put(VenueActionCreators.setRoutes(resData))

        if (isFunction(cb)) {
            yield call(cb, true)
        }
    } catch (e) {
        console.log(e, venueId)
        if (isFunction(cb)) {
            yield call(cb, false)
        }
        TOAST.error(e.response.message)
    } finally {
        yield put(AppActionCreators.hideLoader())
    }
}


function* fetchExhibitors(action) {
    const { venueId, search, cb } = action.payload

    try {
        // yield put(AppActionCreators.showLoader())
        const resData = yield call(ApiHelper.fetchExhibitors, venueId, search)
        // yield put(VenueActionCreators.setRoutes(resData))

        if (isFunction(cb)) {
            yield call(cb, true, resData)
        }
    } catch (e) {
        if (isFunction(cb)) {
            yield call(cb, false, e.response.errors)
        }
        TOAST.error(e.response.message)
    } finally {
        // yield put(AppActionCreators.hideLoader())
    }
}

export default function* venueSaga() {
    yield all([
        takeLatest(VenueActionTypes.REQUEST_FETCH_VENUES, fetchVenues),
        takeLatest(VenueActionTypes.REQUEST_FETCH_VENUE_DETAILS, fetchVenueDetails),
        takeLatest(VenueActionTypes.REQUEST_ADD_VENUES, createVenues),
        takeLatest(VenueActionTypes.REQUEST_FETCH_LAYERS, fetchLayers),
        takeLatest(VenueActionTypes.REQUEST_FETCH_LAYER_DETAILS, fetchLayerDetails),
        takeLatest(VenueActionTypes.REQUEST_ADD_LAYER, createLayer),
        takeLatest(VenueActionTypes.REQUEST_UPDATE_LAYER, updateLayer),
        takeLatest(VenueActionTypes.REQUEST_DELETE_LAYER, deleteLayer),
        takeLatest(VenueActionTypes.REQUEST_FETCH_POINTS, fetchPoints),
        takeLatest(VenueActionTypes.REQUEST_FETCH_LAYER_POINTS, fetchLayerPoints),
        takeLatest(VenueActionTypes.REQUEST_FETCH_VENUE_POINTS, fetchVenuePoints),
        takeLatest(VenueActionTypes.REQUEST_ADD_POINTS, createPoints),
        // takeLatest(VenueActionTypes.REQUEST_UPDATE_POINTS, updatePoints),
        takeLatest(VenueActionTypes.REQUEST_DELETE_POINTS, deletePoints),
        takeLatest(VenueActionTypes.REQUEST_FETCH_ROUTES, fetchRoutes),
        takeLatest(VenueActionTypes.REQUEST_FETCH_VENUE_ROUTES, fetchVenueRoutes),
        takeLatest(VenueActionTypes.REQUEST_UPDATE_ROUTES, updateRoutes),
        takeLatest(VenueActionTypes.REQUEST_GENERATE_ROUTES, generateRoutes),
        takeLatest(VenueActionTypes.REQUEST_UPDATE_LINES, updateLines),
        takeLatest(VenueActionTypes.REQUEST_FETCH_PATH, fetchPath),
        takeLatest(VenueActionTypes.REQUEST_FETCH_ALL_LINES, fetchAllLines),
        takeLatest(VenueActionTypes.REQUEST_FETCH_EXHIBITORS, fetchExhibitors),

    ]);
}
