import I from 'immutable'


import { asImmutable, removeListItem, updateListItem } from '../../helpers/utils'

import AppActionTypes from './AppActionTypes'


const initialState = I.Map({
  initialLoad: true,
  showLoader: true,
  // showSaveButton: false,
  // backgroundSaveInProgress: false,
  user: I.Map({
    userName: "BIBIN KJ",
    userEmail: "bibin@corbelbiz.com"
  }),
  lines: I.List(),
  markers: I.fromJS([
    {
      id: 1583297901931,
      title: 'Title ',
      name: 'NAME 1',
      position: {
        lat: 18.312810846425457,
        lng: 9.843750000000002
      }
    },
    {
      id: 1583297902296,
      title: 'Title ',
      name: 'NAME 2',
      position: {
        lat: 19.312810846425457,
        lng: 3.143750000000002
      }
    },
    {
      id: 1583297902616,
      title: 'Title ',
      name: 'NAME 3',
      position: {
        lat: 17.312810846425457,
        lng: 12.043750000000002
      }
    },
    {
      id: 1583297903040,
      title: 'Title ',
      name: 'NAME 4',
      position: {
        lat: 18.912810846425457,
        lng: 14.843750000000002
      }
    }
  ]),
  // layers: I.fromJS([
  //   {
  //     id: 1584084022571,
  //     name: 'Layer 1',
  //     point1: { lat: 18.97902595325528, lng: -14.853515625000002 },
  //     point2: { lat: 14.43468021529728, lng: 5.097656250000001 },
  //     image: 'https://www.roomsketcher.com/wp-content/uploads/2016/10/1-Bedroom-Floor-Plan-600x450.jpg'
  //   },
  //   {
  //     id: 1584084022574,
  //     name: 'Layer 2',
  //     rotation: 0,
  //     opacity: 0.75,
  //     point1: { lat: 7.449624260197829, lng: -18.369140625000004 },
  //     point2: { lat: -0.21972602392080884, lng: -10.986328125000002 },
  //     image: 'https://www.roomsketcher.com/wp-content/uploads/2016/10/1-Bedroom-Floor-Plan-600x450.jpg'
  //   },
  //   {
  //     id: 1584084031445,
  //     name: 'Layer 3',
  //     rotation: 0,
  //     opacity: 0.75,
  //     point1: { lat: 7.885147283424331, lng: 9.052734375000002 },
  //     point2: { lat: -6.664607562172573, lng: 28.4765625 },
  //     image: 'https://www.iosea.co.uk/wp-content/uploads/Drumgarry-Ardvaser-Floor-1-004.jpg'
  //   }
  // ]),
  venues:I.List(),
  venues2: I.fromJS([{
    venue_id: 234,
    venue_name: "ISC 2020",
    address: '',
    // location: {
    venue_latitude:  15.766483542885926,
    venue_longitude: -7.476196289062501,
    // },
    image: "https://i5.walmartimages.ca/images/Large/046/887/999999-57836046887.jpg",
    current: 300,
    total: 500,
  },
  {
    venue_id: 2344,
    venue_name: "IPC 2020",
    venue_latitude: 10.31491928581316,
    venue_longitude: 2.1093750000000004,
    image: "https://i5.walmartimages.ca/images/Large/046/887/999999-57836046887.jpg",
    current: 400,
    total: 500,
  }]),


  tutorials: I.List(),
  tutorials1: I.fromJS([{
    id: 234,
    name: "How to Add Venue?",
    image: "https://i5.walmartimages.ca/images/Large/046/887/999999-57836046887.jpg",
    time: "3:10",
  },
  {
    id: 2344,
    name: "How to Navigate?",
    image: "https://i5.walmartimages.ca/images/Large/046/887/999999-57836046887.jpg",
    time: "3:00"
  }]),
  features: I.List(),
  features1: I.fromJS([{
    id: 231,
    name: "Branding",
    image: "/images/branding.png",
  },
  {
    id: 232,
    name: "Speakers",
    image: "/images/lecture.png",
  },
  {
    id: 233,
    name: "Sponsors",
    image: "/images/investor.png",
  },
  {
    id: 234,
    name: "Advertisers",
    image: "/images/announce.png",
  },
  {
    id: 235,
    name: "Abstract Access",
    image: "/images/abstract-access.png",
  },
  {
    id: 236,
    name: "Mobile Networking",
    image: "/images/social.png",
  },
  {
    id: 237,
    name: "Logistics",
    image: "/images/airplane.png",
  },
  {
    id: 238,
    name: "Awards",
    image: "/images/medal.png",
  },
  ])
  // }),

  // eventTypes: I.List(),
  // socialMedias: I.List(),
  // countries: I.List(),
  // programFunctionalities: I.List()
})

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case AppActionTypes.SHOW_LOADER:
      return state.set('showLoader', true)
    case AppActionTypes.HIDE_LOADER:
      return state.set('showLoader', false)
    case AppActionTypes.SHOW_DEVICE_PREVIEW:
      return state.set('showDevicePreview', true)
    case AppActionTypes.HIDE_DEVICE_PREVIEW:
      return state.set('showDevicePreview', false)
    case AppActionTypes.SET_SHOW_SAVE_BUTTON:
      return state.set('showSaveButton', !!payload.status)
    case AppActionTypes.SET_BACKGROUND_SAVE_STATUS:
      return state.set('backgroundSaveInProgress', !!payload.status)
    case AppActionTypes.SET_USER_DATA:
      return state.set('user', state.get('user').merge(asImmutable(payload.user))).set('initialLoad', false)

    case AppActionTypes.SET_VENUES:
      return state.set('venues', asImmutable(payload.data)).set('initialLoad', false)

    case AppActionTypes.SET_EVENT_TYPES_LIST:
      return state.set('eventTypes', payload.items)
    case AppActionTypes.SET_SOCIAL_MEDIAS_LIST:
      return state.set('socialMedias', payload.items)
    case AppActionTypes.SET_PROGRAM_FUNCTIONALITY_LIST:
      return state.set('programFunctionalities', payload.items)
    default:
      return state
  }
}
