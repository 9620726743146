import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
// import { createBrowserHistory } from 'history'

import createSagaMiddleware from 'redux-saga'
import { createLogger } from "redux-logger"
// import { connectRouter, routerMiddleware } from 'connected-react-router'

import RootSaga from './root-saga'

import AppReducer from './app/AppReducer'
import VenueReducer from './venue/VenueReducer'

const sagaMiddleware = createSagaMiddleware()
// const mHistory = createBrowserHistory({ basename: '/' })
// const middleware = routerMiddleware(mHistory)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// const routerReducer = connectRouter(mHistory)
const reducers = {
  // router: routerReducer,
  app: AppReducer,
  venue: VenueReducer
}

const logger = createLogger({
  // ...options
})

let store
// if (process.env.ENABLE_REDUX_DEV_TOOLS === "True") {
store = createStore(
  combineReducers(reducers),
  composeEnhancers(
    applyMiddleware(
      // middleware,
      sagaMiddleware,
      // routerMiddleware(mHistory),
      // logger
    )
  )
)
// } else {
//   store = createStore(
//     combineReducers(reducers),
//     compose(
//       applyMiddleware(
//         middleware,
//         sagaMiddleware,
//         routerMiddleware(mHistory)
//       )
//     )
//   )
// }

sagaMiddleware.run(RootSaga)

export default store
// export const history = mHistory
